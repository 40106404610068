import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ManageSubscription from "../pages/manageSubscription/ManageSubscription";
import ManageSubscriptionAdmin from "../pages/manageSubscriptionAdmin/ManageSubscription";

function PageRouter() {
  return (
    <Router>
      <div className="App w-full">
        <Routes>
          <Route exact path="/" element={<ManageSubscription />}></Route>
          <Route exact path="/admin" element={<ManageSubscriptionAdmin />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default PageRouter;
