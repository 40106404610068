import React, { useRef, useState } from "react";
import axios from "axios";
import styles from "./ManageSubscription.module.css";
import { useNavigate } from "react-router-dom";
import SubscriptionList from "../../components/admin/subscriptionList/SubscriptionList";
import SuccessAlert from "../../components/admin/successAlert/SuccessAlert";
import { AdminBaseUrl } from "../../services/BaseUrl";
import StatusBanner from "../../components/admin/statusBanner/StatusBanner";

const Index = () => {
  const [otpGenerate, setOtpGenerate] = useState(false);
  const [otpValidate, setOtpValidate] = useState(false);
  const [preferenceSuccess, setPreferenceSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false)

  const [invalidEmail, setInvalidEmail] = useState(false);
  const [otpValidError, setOtpValidError] = useState(false);
  const emailRef = useRef();
  const otpRef = useRef();

  function isValidEmail(email) {
    var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Test the email against the pattern and return true if it matches, false otherwise
    return emailPattern.test(email);
  }

  const genrateOtp = () => {
    setBtnLoader(true)

    if (isValidEmail(emailRef.current.value)) {
      setInvalidEmail(false);
      axios({
        method: "post",
        url: `${AdminBaseUrl}/?endpoint=admin_login`,
        withCredentials: false,
        data: {
          email: emailRef.current.value,
        },
      })
        .then((response) => {
          setBtnLoader(false)

          console.log(response, "responseresponse");
          setOtpGenerate(true);
          setSuccess(response?.data?.message || "Otp Generated successfully");
          setTimeout(() => {
            setSuccess(false);
          }, 2500);
          //setPost(response.data);
        })
        .catch((err) => {
          setBtnLoader(false)
          setError(err?.response?.data?.message || "Something went wrong");
          setTimeout(() => {
            setError(false);
          }, 2500);
        });
    } else {
      setInvalidEmail(true);
    }
  };
  const validateOtpSubmit = () => {

    // if (isValidEmail(otpRef.current.value)) {
    //   setOtpValidError(true);
    //   return;
    // }
    setOtpValidError(false);
    axios({
      method: "post",
      url: `${AdminBaseUrl}/?endpoint=get_records`,
      withCredentials: false,
      data: {
        customer_email: otpRef.current.value,
        //verification_code: otpRef.current.value,
      },
    })
      .then((response) => {
        console.log(response, "responseresponse");
        setSuccess(response?.data?.message || "Verified successfully");
        sessionStorage.setItem("login", response?.data?.token);
        sessionStorage.setItem("email", emailRef?.current?.value);
        sessionStorage.setItem("otpemail", otpRef?.current?.value);
        setOtpValidate(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2500);
      })
      .catch((err) => {
        setError(err?.response?.data?.message || "Something went wrong");
        setTimeout(() => {
          setError(false);
        }, 2500);
      });
  };
  const navigate = useNavigate();

  const manageOthers = () => {
    setOtpValidate(false);
    setOtpGenerate(true);
    setPreferenceSuccess(false)
  }
  return (
    <>
      {success && <StatusBanner message={success} type={"success"} />}
      {error && <StatusBanner message={error} type={"error"} />}
      {preferenceSuccess ? (
        <SuccessAlert setPreferenceSuccess={setPreferenceSuccess} manageOthers={manageOthers}/>
      ) : (
        <div className={!otpValidate ? styles.bodyWrapper : styles.wholeBodyWrapper}>
          { <div className={styles.logoWrapper}>
            <img src="/images/R&TAsia_Logo_Primary_RGB.png" />
            <img src="/images/LWKA (1).png" />


          </div>}
          <h1 className={styles.heading}>Admin - Manage your Subscription</h1>
          {!otpValidate ? (
            <div>
              {/* <p className={styles.subHeading}>
                Select which kind of email communication(s) you would like to
                receive:
              </p> */}

              {otpGenerate ? (
                <>
                  <label for="email" className={styles.emailHeading}>
                    Enter Customer Email Address
                  </label>
                  <input
                    ref={otpRef}
                    type="email"
                    id="email"
                    className={styles.mailInput}
                    onKeyUp={(e) => {
                      if (e?.target?.value?.length === 6) {
                        setOtpValidError(false);
                      }
                    }}
                  />
                  {otpValidError && (
                    <p className={styles.errorMsg}>Please enter a valid ID</p>
                  )}
                  <div className={styles.btnSet}>
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        setOtpGenerate(false);
                      }}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        validateOtpSubmit();
                      }}
                      className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </>
              )
                :
                <>
                  <div>
                    <label for="email" className={styles.emailHeading}>
                      Email Address
                    </label>
                    <input
                      ref={emailRef}
                      type="email"
                      id="email"
                      className={styles.mailInput}
                    />
                    {invalidEmail && (
                      <p className={styles.errorMsg}>Please enter a valid email</p>
                    )}
                    <p className={"text-center"}>
                      <button className={btnLoader ? `linkBtn disabled` : `linkBtn`} disabled={btnLoader} onClick={() => genrateOtp()}>
                        {/* {!otpGenerate ? "Generate OTP" : "Resend OTP"} */}
                        {btnLoader ? "Verifying ..." : !otpGenerate ? "Submit" : "Retry"}
                      </button>
                    </p>
                  </div>
                </>
              }
            </div>
          ) : (
            <SubscriptionList
              // otpRefId={otpRef.current.value}
              // emailId={emailRef?.current?.value}
              setOtpValidate={setOtpValidate}
              setOtpGenerate={setOtpGenerate}
              setSuccess={setSuccess}
              setError={setError}
              setPreferenceSuccess={setPreferenceSuccess}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Index;
