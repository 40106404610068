import React, { useRef, useState } from "react";
import axios from "axios";
import styles from "./ManageSubscription.module.css";
import { useNavigate } from "react-router-dom";
import SubscriptionList from "../../components/subscriptionList/SubscriptionList";
import SuccessAlert from "../../components/successAlert/SuccessAlert";
import { BaseUrl } from "../../services/BaseUrl";
import StatusBanner from "../../components/statusBanner/StatusBanner";

const Index = () => {
  const [otpGenerate, setOtpGenerate] = useState(false);
  const [otpValidate, setOtpValidate] = useState(false);
  const [preferenceSuccess, setPreferenceSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false)
  const [otpValidError, setOtpValidError] = useState(false);
  const emailRef = useRef();
  const otpRef = useRef();

  function isValidEmail(email) {
    var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Test the email against the pattern and return true if it matches, false otherwise
    return emailPattern.test(email);
  }

  const genrateOtp = () => {
    setBtnLoader(true)
    if (isValidEmail(emailRef.current.value)) {
      setInvalidEmail(false);
      axios({
        method: "post",
        url: `${BaseUrl}/?endpoint=generate_otp`,
        withCredentials: false,
        data: {
          email: emailRef.current.value,
        },
      })
        .then((response) => {
          setBtnLoader(false)
          console.log(response, "responseresponse");
          setOtpGenerate(true);
          setSuccess(response?.data?.message || "Otp Generated successfully");
          setTimeout(() => {
            setSuccess(false);
          }, 2500);
          //setPost(response.data);
        })
        .catch((error) => {
          setBtnLoader(false)
          setError(error?.response?.data?.message || "Something went wrong");
          setTimeout(() => {
            setError(false);
          }, 2500);
        });
    } else {
      setInvalidEmail(true);
      setBtnLoader(false)
    }
  };
  const validateOtpSubmit = () => {
    if (otpRef?.current?.value?.length !== 6) {
      setOtpValidError(true);
      return;
    }
    setOtpValidError(false);
    axios({
      method: "post",
      url: `${BaseUrl}/?endpoint=verify`,
      withCredentials: false,
      data: {
        email: emailRef.current.value,
        verification_code: otpRef.current.value,
      },
    })
      .then((response) => {
        console.log(response, "responseresponse");
        setSuccess(response?.data?.message || "Otp Verified successfully");
        sessionStorage.setItem("login", response?.data?.token);
        sessionStorage.setItem("email", emailRef?.current?.value);
        setOtpValidate(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2500);
      })
      .catch((error) => {
        setError(error?.data?.message || "Something went wrong");
        setTimeout(() => {
          setError(false);
        }, 2500);
      });
  };
  const navigate = useNavigate();
  return (
    <>
      {success && <StatusBanner message={success} type={"success"} />}
      {error && <StatusBanner message={error} type={"error"} />}
      {preferenceSuccess ? (
        <SuccessAlert setPreferenceSuccess={setPreferenceSuccess} />
      ) : (
        <div className={!otpValidate ? styles.bodyWrapper : styles.wholeBodyWrapper}>
          {otpValidate && <div className={styles.logoWrapper}>
            <img src="/images/R&TAsia_Logo_Primary_RGB.png" />
            <img src="/images/LWKA (1).png" />


          </div>}
          <h1 className={styles.heading}>Manage your preferences</h1>
          {!otpValidate ? (
            <div>
              <p className={styles.subHeading}>
                Login to select the types of email communications you would like to receive:
              </p>
              <div>
                <label for="email" className={styles.emailHeading}>
                  Email Address
                </label>
                <input
                  ref={emailRef}
                  type="email"
                  id="email"
                  className={styles.mailInput}
                />
                {invalidEmail && (
                  <p className={styles.errorMsg}>Please enter a valid email</p>
                )}
                <p className={"text-center"}>
                  <button className={btnLoader ? `linkBtn disabled` : `linkBtn`} disabled={btnLoader} onClick={() => genrateOtp()}>
                    {btnLoader ? "Generating ..." : !otpGenerate ? "Generate OTP" : "Resend OTP"}
                  </button>
                </p>
              </div>
              {otpGenerate && (
                <>
                  <label for="email" className={styles.emailHeading}>
                    Enter OTP
                  </label>
                  <input
                    ref={otpRef}
                    type="number"
                    id="email"
                    className={styles.mailInput}
                    onKeyUp={(e) => {
                      if (e?.target?.value?.length === 6) {
                        setOtpValidError(false);
                      }
                    }}
                  />
                  {otpValidError && (
                    <p className={styles.errorMsg}>Please enter a valid OTP</p>
                  )}
                  <div className={styles.btnSet}>
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        setOtpGenerate(false);
                      }}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        validateOtpSubmit();
                      }}
                      className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <SubscriptionList
              emailId={emailRef?.current?.value}
              setOtpValidate={setOtpValidate}
              setOtpGenerate={setOtpGenerate}
              setSuccess={setSuccess}
              setError={setError}
              setPreferenceSuccess={setPreferenceSuccess}
            />
          )}
        </div>
      )}
      <div className={styles.fixedBlock}>
        <div align="justify" className={styles.footerBlock}><p>By submitting this form, you hereby agree to use this website in accordance with the <a href="https://www.rajahtannasia.com/terms-of-use/"><span className={styles.redText}>Terms & Conditions</span></a> of Use, and consent to us collecting, using and disclosing your personal data in accordance with our <a href="https://www.rajahtannasia.com/privacy"><span className={styles.redText}>Privacy Policy</span></a>, including sending you electronic communications such as legal updates and event invitations. You may unsubscribe or manage your subscriptions at any time.</p></div>
      </div>
    </>
  );
};

export default Index;
