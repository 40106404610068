import React, { useEffect } from "react";
import styles from "./SubscriptionList.module.css";
import axios from "axios";
import { AdminBaseUrl } from "../../../services/BaseUrl";
import { useState } from "react";
import ErrorAlert from "../errorAlert/ErrorAlert";

const Index = ({
  setOtpValidate,
  setOtpGenerate,
  setSuccess,
  setError,
  setPreferenceSuccess,
}) => {
  const [preference, setPreference] = useState();
  const [validEmailError, setValidEmailError] = useState(false);
  console.log(validEmailError, "email error");
  const splitDataUsingCategory = (arrayOfObjects) => {
    const groupedData = arrayOfObjects.reduce((acc, obj) => {
      const { category, ...rest } = obj; // Destructure the object
      if (!acc[category]) {
        acc[category] = []; // If category key doesn't exist in accumulator, initialize it as an empty array
      }
      acc[category].push(rest); // Push the rest of the object (excluding the category) to the corresponding category array
      return acc;
    }, {});
  };

  console.log(preference, "responseresponseresponse");
  useEffect(() => {
    axios({
      method: "post",
      url: `${AdminBaseUrl}/?endpoint=get_records`,
      withCredentials: false,
      // headers: {
      //   // "ngrok-skip-browser-warning": "69420",
      //   token: sessionStorage.getItem("login"),
      // },
      data: {
        customer_email: sessionStorage.getItem("otpemail"),
      },
    })
      .then((response) => {
        setPreference(response?.data);
        setValidEmailError(false);
      })
      .catch((err) => {
        console.log(err, "email error response");
        setError(err?.response?.data?.message || "Something went wrong");
        setValidEmailError(
          err?.response?.data?.message || "Something went wrong"
        );
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  }, []);

  const groupedData = preference?.records?.reduce((acc, obj) => {
    const { category, ...rest } = obj;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({ category, ...rest });
    return acc;
  }, {});

  const onsubmit = async (event) => {
    event.preventDefault();
    console.log(event.target.elements, "datadata");
    const checkboxes = event.target.elements["preferenceCheckbox"];

    // Array to store checked values
    const checkedValues = [];
   
    // Loop through checkboxes and check if they are checked
    await checkboxes?.forEach((checkbox) => {
      if (checkbox?.checked) {
        if (checkbox?.id !== "all") {
          console.log(checkbox?.getAttribute('data-name'),'dssdsdsdsdsds');
          checkedValues?.push(`${checkbox?.getAttribute('category')}_${checkbox?.getAttribute('data-name')}`);
        }
      }
    });
    console.log(checkboxes,checkedValues,groupedData,'checkboxescheckboxes');
    const filteredA = {};
    for (const key in groupedData) {
      if (groupedData.hasOwnProperty(key)) {
        filteredA[key] = groupedData[key]
          .filter((item) => (checkedValues.includes(`${item.category}_${item.name}`)))
          ?.map((el) => el.name);
      }
    }
    axios({
      method: "put",
      url: `${AdminBaseUrl}/?endpoint=update_contact`,
      withCredentials: false,
      // headers: {
      //   "ngrok-skip-browser-warning": "69420",
      //   token: sessionStorage.getItem("login"),
      // },
      data: {
        customer_email: sessionStorage.getItem("otpemail"),
        subscribed_category: filteredA,
      },
    })
      .then((response) => {
        console.log(response, "responseresponse");
        setSuccess(response?.data?.message || "Submitted successfully");
        setTimeout(() => {
          setSuccess(false);
          setPreferenceSuccess(true);
        }, 2500);
        //setPost(response.data);
      })
      .catch((err) => {
        setError(err?.response?.data?.message || "Something went wrong");
        setTimeout(() => {
          setError(false);
        }, 1500);
      });

    // Handle form submission with checkedValues
    console.log("Checked values:", checkedValues);
    return;
  };

  const handleBack = () => {
    setOtpValidate(false);
    setOtpGenerate(false);
    setPreferenceSuccess(false);
  };
  const changeSelectAll = (e, category) => {
    console.log(e.target.checked, category, "valueeeeeeeeE");
    const checked = e.target.checked;
    // Select or unselect all preference checkboxes based on the "Select all" checkbox state
    const checkboxes = document.querySelectorAll(
      "[data-category='" + category + "']"
    );

    checkboxes.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  };

  const checkboxes = document.querySelectorAll(
    "[name='" + "preferenceCheckbox" + "']"
  );

  const Defaultarr = [];

  checkboxes.forEach((checkbox) => {
    const category = checkbox.getAttribute("data-category");
    if (checkbox?.checked === false) {
      console.log("checkbox >>>>", checkbox?.id, category, checkbox?.checked);
      Defaultarr[category] = checkbox?.checked;
    }
  });

  const checkboxChange = (category) => {
    const allCheckboxes = document.querySelectorAll(`[data-category='${category}']`);
    const allChecked = Array.from(allCheckboxes).every((checkbox) => checkbox.checked);
    const selectAllCheckbox = document.querySelector(`[id='all'][category='${category}']`);
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = allChecked;
    }
  }

  return (
    <>
      {validEmailError ? (
        <>
          <ErrorAlert handleBack={handleBack} message={validEmailError} />
        </>
      ) : (
        <form onSubmit={onsubmit} action={false}>
          <div>
          <h2 style={{textAlign: "center"}} >You are managing subscriptions for {sessionStorage.getItem("otpemail")}</h2>

            {Object.entries(groupedData || []).map(([category, items]) => (
              <div key={category}>
                <h2 style={{ margin: "0 14px 0 0" }}>{category}</h2>
                <div className="" style={{ display: "flex" }}>
                  <label
                    className={styles.container}
                    style={{ marginBottom: "15px",marginTop: "20px", paddingLeft: "20px" }}>
                    <input
                      type="checkbox"
                      className={styles.check1}
                      id={"all"}
                      category={category}
                      defaultChecked={preference?.subscribed_for_all?.includes(
                        category
                      )}
                      onChange={(e) => changeSelectAll(e, category)}
                    />
                    <span className={styles.checkmark}></span>{" "}
                    <span style={{paddingLeft:'12px'}}>Select all</span>
                  </label>
                </div>
                <ul className={styles.listWrapper}>
                  {items.map((record) => (
                    <li key={record.id}>
                      <label className={styles.container} for={record.id}>
                        <input
                          type="checkbox"
                          className={styles.check1}
                          id={record.id}
                          name="preferenceCheckbox"
                          data-name={record.name}
                          data-category={category}
                          category={category}
                          defaultChecked={
                            record?.selected ||
                            preference?.subscribed_categories?.[category]?.includes(
                              record?.name
                            ) ||
                            false
                          }
                          onChange={() => checkboxChange(category)}

                        />{" "}
                        <span className={styles.checkmark}></span>{" "}
                        <span>{record?.name}</span>
                        <p className={styles.subcContent}>
                          {record?.description}
                        </p>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          {/* <h2>{"Subscribed preferences"}</h2>
      <ul className={styles.listWrapper}>
        {preference?.subscribed_for?.map((record) => {
          return (
            <>
              <li style={{ opacity: "0.6" }}>
                <label
                  className={styles.container}
                  for={record}
                  style={{ cursor: "no-drop" }}>
                  <input
                    type="checkbox"
                    className={styles.check1}
                    id={record}
                    checked={true}
                    disabled
                  />{" "}
                  <span className={styles.checkmark}></span>{" "}
                  <span className={styles.subcContent}>{record}</span>
                </label>
              </li>
            </>
          );
        })}
      </ul> */}
          <div className={styles.btnSet}>
          <button
              type="button"
              className="btn"
              onClick={() => {
                setOtpValidate(false);
                setOtpGenerate(true);
                // sessionStorage.clear();
              }}>
              Manage others
            </button>
            <button
              type="button"
              className="btn btn-cancel"
              onClick={() => {
                setOtpValidate(false);
                setOtpGenerate(false);
                sessionStorage.clear();
              }}>
              Cancel
            </button>
            <button
              type="submit"
              // onClick={() => setPreferenceSuccess(true)}
              className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default Index;
